import React, { Fragment, PureComponent } from 'react'
import { Layout, LightBox } from '../../components'
import '../style.less'
import '../gallery.less'

class Gallery extends PureComponent {
  render () {
    return (
      <Fragment>
        <Layout
          title={'Gallery'}
          location={this.props.location}
          description={'The Woolpack Gallery'}
          keywords={['The Woolpack Gallery', 'Pub Pictures']}
        >
          <div className='gallery-container'>
            <LightBox />
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default Gallery
